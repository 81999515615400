import React from "react"
import useTranslations from "../../../utils/useTranslations"
import ComparisonPanels from "../../../components/comparisonPanels"

import heroImgWebp from "../../../images/products/heroes/premium-web.webp"
import heroImg from "../../../images/products/heroes/premium-web.png"
import heroImgWebp2x from "../../../images/products/heroes/premium-web@2x.webp"
import heroImg2x from "../../../images/products/heroes/premium-web@2x.png"

import Button from "../../../components/button"
import Logo from "../../../components/logo"
import backgrounds from "../../../components/backgrounds"


const heroImgAlt = "Buy BitTorrent Web Pro"

class BittorrentWebPremium extends React.Component {

  render(){

    const { text, pageName } = this.props.pageContext
    const t = useTranslations(text)
    const { unlocalizedPath }  = this.props.pageContext

    return (
      <>
        <div className="top-section-container p-0 pb-md-5 pt-md-3 background-noise-dark" style={backgrounds.darkCorners}>
          <div className="container text-dark py-2">
            <div className="row text-center mt-3 mt-sm-5">
              <div className="col-md-6 mt-md-4 order-md-1 px-md-0 pt-lg-5">
                <Logo color="black" tag="p" productName="Web Premium" className="mb-4"/>
                <img src={heroImg} alt={heroImgAlt} className="img-fluid d-block d-md-none ml-auto position-relative" style={{left: '6%'}}></img>
                <div style={{fontSize: '10px'}} className="position-relative d-block d-md-none mt-2 mb-4"><a id="products-win-btweb-premium-bt-now-mobile" href="https://now.bt.co/" target="_blank" rel="noopenner noreferrer" className="text-dark text-decoration-none">now.bt.co</a> content displayed pursuant to license.</div>
                <div className="text-size-24 text-spacing-1 font-weight-normal"><h1 className="text-size-24 font-weight-normal m-0 d-inline">{t(`Safe torrent downloads.`)}</h1></div>
                <Button  href={`${unlocalizedPath}#comparison-panels`} id="products-win-btweb-premium-hero-cta" className=" btn-primary mt-3 mt-md-4 mb-4 button-filled text-spacing-1 px-5">{t(`BUY NOW`)}</Button>
              </div>
              <div className="col-md-6 mb-5 order-md-2 d-flex justify-content-center flex-column">
                {/*<img src={heroImg} alt={heroImgAlt} className="img-fluid d-none d-md-block"></img>*/}

                <picture>
                  <source srcSet={`${heroImgWebp} 1x, ${heroImgWebp2x} 2x`} type="image/webp"></source>
                  <img srcSet={`${heroImg} 1x, ${heroImg2x} 2x`} src={heroImg} alt={heroImgAlt} className="img-fluid d-none d-md-block"></img>
                </picture>
                <div style={{fontSize: '10px', left: '-5%'}} className="position-relative d-none d-md-block mt-2"><a id="products-win-btweb-premium-bt-now" href="https://now.bt.co/" target="_blank" rel="noopenner noreferrer" className="text-dark text-decoration-none">now.bt.co</a> content displayed pursuant to license.</div>

              </div>
            </div>
          </div>
        </div>

        <div className="container my-5 py-md-5">
        	<p className="premium-title">{t(`<b>BitTorrent</b> Web Pro`)}</p>
        	<p className="premium-description">
        		{t(`proDescription`)}
        	</p>

        	<p className="premium-title mt-5 ">{t(`<b>BitTorrent</b> Web Pro+VPN`)}</p>
        	<p className="premium-description">
        		{t(`provpnDescription`)}
        	</p>
        </div>

        <div className="container-fluid position-relative overflow-hidden background-noise-dark" style={backgrounds.darkCorners}>
          <div className="container my-5 z-2">
            <p className="text-center font-weight-bold feature-list-title-2 mb-3 text-dark">{t(`Compare`)}</p>
            <h2 className="text-center mb-5 text-size-24 font-weight-normal text-dark">
                {t(`Compare BitTorrent Web Premium Versions`)}
            </h2>

            <ComparisonPanels pageContext={{ "text": text, "pageName":pageName, "isMac":true}}/>
          </div>
        </div>
      </>
    )

  }
}

export default BittorrentWebPremium
